import { NODE_ENV } from "../../constants/env";
import { useURLs } from "../../constants/api.urls";


const GET_REDIS_URL = useURLs[NODE_ENV].GET_REDIS_URL;
const SET_REDIS_URL = useURLs[NODE_ENV].SET_REDIS_URL;

export const setRedis = async ({ key, value }) => {
  try {
    if (!value) {
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("client_id", useURLs[NODE_ENV].CLIENT_ID);
    myHeaders.append("client_secret", useURLs[NODE_ENV].CLIENT_SECERET);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        key,
        value: value,
      }),
    };
    const url = useURLs[NODE_ENV].SET_REDIS_URL;
    await fetch(url, requestOptions);
  } catch (error) {
    console.error("error getting CATEGORY_FILTERS", error);
  }
};

export const getRedis = async (key) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("client_id", useURLs[NODE_ENV].CLIENT_ID);
    myHeaders.append("client_secret", useURLs[NODE_ENV].CLIENT_SECERET);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    const url = useURLs[NODE_ENV].GET_REDIS_URL;
    const res = await fetch(`${url}?id=${key}`, requestOptions);
    const data = await res.json();

    return data;
  } catch (error) {
    return false;
  }
};
export const GetFromRedis = async (key) => {
  const { data } = await useFetch(`${GET_REDIS_URL}?id=${key}`, {
    method: "GET",
    key: key,
    headers: {
      "Content-Type": "application/json",
      "client_id": useURLs[NODE_ENV].CLIENT_ID,
      "client_secret": useURLs[NODE_ENV].CLIENT_SECERET
    },
  });
  return data
}


export const SetToRedis = async (key, value) => {
  const { data } = await useFetch(`${SET_REDIS_URL}?id=${key}`, {
    method: "POST",
    key: key,
    headers: {
      "Content-Type": "application/json",
      "client_id": useURLs[NODE_ENV].CLIENT_ID,
      "client_secret": useURLs[NODE_ENV].CLIENT_SECERET
    },
    body: JSON.stringify({ key, value }),
  });
  return data
}
