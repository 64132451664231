

const envType = { 0: "development", 1: "staging", 2: "production" } as const;
type EnvType = typeof envType[keyof typeof envType];
const NODE_ENV: EnvType = envType[2];
const CLD_ENV: EnvType = envType[2];
const CMS_ENV: EnvType = envType[2];

// Export using ES modules syntax
export { NODE_ENV, CLD_ENV, CMS_ENV };
