export const useConstants = Object.freeze({
  APPLY: {
    de_DE: "Anwenden",
    en_GB: "Apply",
    es_ES: "Aplicar",
    fr_BE: "Appliquer",
    it_IT: "Applica",
    nl_BE: "Toepassen",
  },

  PRICE: {
    de_DE: "Preis",
    en_GB: "Price",
    es_ES: "Precio",
    fr_BE: "Prix",
    it_IT: "Prezzo",
    nl_BE: "Prijs",
  },
  DOWNLOAD_IMAGES: {
    de_DE: "Bilder herunterladen",
    en_GB: "Download images",
    es_ES: "Descargar imágenes",
    fr_BE: "Télécharger des images",
    it_IT: "Scarica immagini",
    nl_BE: "Afbeeldingen downloaden",
  },
  DOWNLOAD: {
    de_DE: "Herunterladen",
    en_GB: "Download",
    es_ES: "Descargar",
    fr_BE: "Télécharger",
    it_IT: "Scarica",
    nl_BE: "Downloaden",
  },
  PRESALE: {
    de_DE: "Vorverkaufsprozentsatz",
    en_GB: "Presale percentage",
    es_ES: "Porcentaje de preventa",
    fr_BE: "Pourcentage de prévente",
    nl_BE: "Percentage voorverkoop",
  },
  SAVE: {
    TITLE: {
      de_DE: "Erfolg",
      en_GB: "Success",
      es_ES: "Éxito",
      fr_BE: "Succès",
      nl_BE: "Succes",
    },
    DESC: {
      de_DE: "Änderungen wurden erfolgreich gespeichert.",
      en_GB: "Changes are saved successfully.",
      es_ES: "Los cambios se han guardado correctamente.",
      fr_BE: "Les modifications ont été enregistrées avec succès.",
      nl_BE: "Wijzigingen zijn succesvol opgeslagen.",
    },
  },
  BOOLEAN_FILTERS: ["status_preorder", "price_promo", "in_stock","in_reserved"],
  SAVE_BUTTON: {
    de_DE: "Speichern",
    en_GB: "Save",
    es_ES: "Guardar",
    fr_BE: "Enregistrer",
    nl_BE: "Opslaan",
  },
  CANCEL_BUTTON: {
    de_DE: "Abbrechen",
    en_GB: "Cancel",
    es_ES: "Cancelar",
    fr_BE: "Annuler",
    nl_BE: "Annuleren",
  },
  NO_PRODUCTS: {
    de_DE: "Keine Produkte gefunden!",
    en_GB: "No products found!",
    es_ES: "¡No se encontraron productos!",
    fr_BE: "Aucun produit trouvé !",
    nl_BE: "Geen producten gevonden!",
  },
  NO_PRODUCT: {
    de_DE: "Kein Produkt gefunden!",
    en_GB: "No product found!",
    es_ES: "¡No se encontró ningún producto!",
    fr_BE: "Aucun produit trouvé !",
    nl_BE: "Geen product gevonden!",
  },
  LABELS_BOOLEAN_FILTERS: {
    status_preorder: {
      de_DE: "Vorbestellung",
      en_GB: "Pre-Order",
      es_ES: "Pedido anticipado",
      fr_BE: "Pré-commande",
      it_IT: "Pre-ordine",
      nl_BE: "Pre-Order",
    },
    price_promo: {
      de_DE: "Preis-Promo",
      en_GB: "Price promo",
      es_ES: "Precio promocional",
      fr_BE: "Prix promo",
      it_IT: "Prezzo promo",
      nl_BE: "Prijs promo",
    },
  },
  SORT_FILTERS: {
    price_asc: {
      de_DE: "niedrigster Preis",
      en_GB: "lowest price",
      es_ES: "precio más bajo",
      fr_BE: "prix le moins élevé",
      it_IT: "prezzo più basso",
      nl_BE: "laagste prijs",
    },
    price_desc: {
      de_DE: "höchster Preis",
      en_GB: "highest price",
      es_ES: "precio más alto",
      fr_BE: "prix le plus élevé",
      it_IT: "prezzo più alto",
      nl_BE: "hoogste prijs",
    },
    release_desc: {
      de_DE: "zuletzt veröffentlicht",
      en_GB: "released latest",
      es_ES: "último lanzamiento",
      fr_BE: "dernière sortie",
      it_IT: "ultimo rilascio",
      nl_BE: "laatst uitgebracht",
    },
    release_asc: {
      de_DE: "zuerst veröffentlicht",
      en_GB: "released earliest",
      es_ES: "lanzado primero",
      fr_BE: "première sortie",
      it_IT: "rilasciato prima",
      nl_BE: "vroegst uitgebracht",
    },
    stock_desc: {
      de_DE: "meiste Lagerbestand",
      en_GB: "most stock",
      es_ES: "más stock",
      fr_BE: "stock le plus élevé",
      it_IT: "maggior disponibilità",
      nl_BE: "meeste voorraad",
    },
    stock_asc: {
      de_DE: "geringster Lagerbestand",
      en_GB: "least stock",
      es_ES: "menos stock",
      fr_BE: "stock le plus bas",
      it_IT: "minore disponibilità",
      nl_BE: "minste voorraad",
    },
    name_desc: {
      de_DE: "produktname Z-A",
      en_GB: "product name Z-A",
      es_ES: "nombre del producto Z-A",
      fr_BE: "nom du produit de Z à A",
      it_IT: "nome prodotto Z-A",
      nl_BE: "productnaam Z-A",
    },
    name_asc: {
      de_DE: "produktname A-Z",
      en_GB: "product name A-Z",
      es_ES: "nombre del producto A-Z",
      fr_BE: "nom du produit A-Z",
      it_IT: "nome prodotto A-Z",
      nl_BE: "productnaam A-Z",
    },
    arrival_desc: {
      de_DE: "neueste",
      en_GB: "most recent",
      es_ES: "más reciente",
      fr_BE: "plus récent",
      it_IT: "più recente",
      nl_BE: "meest recent",
    },
  },
  PAGINATION: {
    PREV: {
      de_DE: "Vorherige",
      en_GB: "Previous",
      es_ES: "Anterior",
      fr_BE: "Précédent",
      it_IT: "Precedente",
      nl_BE: "Vorige",
    },
    NEXT: {
      de_DE: "Weiter",
      en_GB: "Next",
      es_ES: "Siguiente",
      fr_BE: "Suivant",
      it_IT: "Avanti",
      nl_BE: "Volgende",
    },
  },
  CATEGORY: {
    de_DE: "KATEGORIE",
    en_GB: "CATEGORY",
    es_ES: "CATEGORÍA",
    fr_BE: "CATÉGORIE",
    it_IT: "CATEGORIA",
    nl_BE: "CATEGORIE",
  },
  ATTRIBUTE: {
    de_DE: "ATTRIBUTE",
    en_GB: "ATTRIBUTES",
    es_ES: "ATRIBUTOS",
    fr_BE: "ATTRIBUTS",
    it_IT: "ATTRIBUTI",
    nl_BE: "KENMERKEN",
  },
  FILTER_RESET: {
    de_DE: "FILTER ZURÜCKSETZEN",
    en_GB: "RESET FILTERS",
    es_ES: "RESTABLECER FILTROS",
    fr_BE: "RÉINITIALISER LES FILTRES",
    it_IT: "RESETTA FILTRI",
    nl_BE: "FILTERS RESETTEN",
  },
  YES: {
    de_DE: "Ja",
    en_GB: "Yes",
    es_ES: "Sí",
    fr_BE: "Oui",
    it_IT: "Sì",
    nl_BE: "Ja",
  },
  NO: {
    de_DE: "Nein",
    en_GB: "No",
    es_ES: "No",
    fr_BE: "Non",
    it_IT: "No",
    nl_BE: "Nee",
  },
  DESCRIPTION: {
    de_DE: "BESCHREIBUNG",
    en_GB: "DESCRIPTION",
    es_ES: "DESCRIPCIÓN",
    fr_BE: "DESCRIPTION",
    it_IT: "DESCRIZIONE",
    nl_BE: "BESCHRIJVING",
  },
  ADDITIONAL_INFO: {
    de_DE: "ZUSÄTZLICHE INFORMATIONEN",
    en_GB: "ADDITIONAL INFORMATION",
    es_ES: "INFORMACIÓN ADICIONAL",
    fr_BE: "INFORMATIONS ADDITIONNELLES",
    it_IT: "INFORMAZIONI AGGIUNTIVE",
    nl_BE: "AANVULLENDE INFORMATIE",
  },

  STOCK_RANK: {
    de_DE: "Lager-Rang",
    en_GB: "Stock Rank",
    es_ES: "Rango de stock",
    fr_BE: "Classement du stock",
    it_IT: "Classifica stock",
    nl_BE: "Voorraadrang",
  },
  STOCK: {
    de_DE: "Bestand",
    en_GB: "Stock",
    es_ES: "Existencias",
    fr_BE: "Stock",
    it_IT: "Scorta",
    nl_BE: "Voorraad",
  },
  AVAILABLE: {
    de_DE: "Verfügbar",
    en_GB: "Available",
    es_ES: "Disponible",
    fr_BE: "Disponible",
    nl_BE: "Beschikbaar",
  },
  PLATFORMS: {
    de_DE: "Plattformen",
    en_GB: "Platforms",
    es_ES: "Plataformas",
    fr_BE: "Plateformes",
    it_IT: "Piattaforme",
    nl_BE: "Platformen",
  },
  SALE_IN_MULTIPLE: {
    de_DE: "Verkauf in mehreren",
    en_GB: "Sale in multiple",
    es_ES: "Venta en múltiples",
    fr_BE: "Vente en plusieurs",
    it_IT: "Vendita multipla",
    nl_BE: "Verkoop in meerdere",
  },
  BO: {
    de_DE: "Nachbestellung",
    en_GB: "Back Order",
    es_ES: "Pedido en espera",
    fr_BE: "Commande en attente",
    it_IT: "Ordine in sospeso",
    nl_BE: "Nabestelling",
  },
  RETAIL_PRICE: {
    de_DE: "Empfohlener Preis",
    en_GB: "Suggested price",
    es_ES: "Precio sugerido",
    fr_BE: "Prix suggéré",
    it_IT: "Prezzo suggerito",
    nl_BE: "Gesuggereerde prijs",
  },
  REFERENCE: {
    de_DE: "Referenz",
    en_GB: "Reference",
    es_ES: "Referencia",
    fr_BE: "Référence",
    it_IT: "Riferimento",
    nl_BE: "Referentie",
  },
  ADD_TO_CART: {
    de_DE: "In den Warenkorb legen",
    en_GB: "Add to cart",
    es_ES: "Añadir al carrito",
    fr_BE: "Ajouter au panier",
    it_IT: "Aggiungi al carrello",
    nl_BE: "Toevoegen aan winkelwagen",
  },
  PRE_ORDER: {
    de_DE: "Vorbestellung",
    en_GB: "Pre-order",
    es_ES: "Preventa",
    fr_BE: "Précommande",
    it_IT: "Preordine",
    nl_BE: "Pre-order",
  },
  DO_U_MEAN: {
    de_DE: "Meinen Sie ",
    en_GB: "Do you mean ",
    es_ES: "¿Te refieres a ",
    fr_BE: "Voulez-vous dire ",
    it_IT: "Intendi ",
    nl_BE: "Bedoelt u ",
  },
  CART: {
    EMPTY_MESSAGE: {
      de_DE: "Ihr Warenkorb ist derzeit leer!",
      en_GB: "Your Cart Is Currently Empty!",
      es_ES: "¡Tu carrito está vacío en este momento!",
      fr_BE: "Votre panier est actuellement vide !",
      nl_BE: "Uw winkelwagen is momenteel leeg!",
    },
    ORDER_FAILS: {
      de_DE: "Bei Ihrer Bestellung ist ein Fehler aufgetreten.",
      en_GB: "Something went wrong with your order.",
      es_ES: "Ha ocurrido un error con tu pedido.",
      fr_BE: "Un problème est survenu avec votre commande.",
      nl_BE: "Er is iets misgegaan met uw bestelling.",
    },
    REF: {
      de_DE: "Ihre Referenz oder Bestellung (optional) :",
      en_GB: "Your reference or purchase order (optional) :",
      es_ES: "Su referencia u orden de compra (opcional) :",
      fr_BE: "Votre référence pour cette commande : (Optionnel)",
      nl_BE: "Uw aankoopreferentie :",
    },
    PAYMENT_BTN: {
      de_DE: "Überprüfen",
      en_GB: "Validate",
      es_ES: "Validar",
      fr_BE: "Valider",
      nl_BE: "Valideren",
    },
    ALERT: {
      INVALID_LOGIN: {
        de_DE: "Bitte melden Sie sich an, um auf die Website zuzugreifen.",
        en_GB: "Please log in to access the website.",
        es_ES: "Por favor, inicia sesión para acceder al sitio web.",
        fr_BE: "Veuillez vous connecter pour accéder au site web.",
        nl_BE: "Log in om toegang te krijgen tot de website.",
      },
      RESET_TITLE: {
        de_DE: "Warenkorb zurückgesetzt",
        en_GB: "Cart Reset",
        es_ES: "Carrito restablecido",
        fr_BE: "Réinitialisation du panier",
        nl_BE: "Winkelwagen gereset",
      },
      RESET_DESC: {
        de_DE: "Ihr Warenkorb wurde zurückgesetzt.",
        en_GB: "Your cart has been reset.",
        es_ES: "Se ha restablecido su carrito.",
        fr_BE: "Votre panier a été réinitialisé.",
        nl_BE: "Uw winkelwagen is gereset.",
      },
      UPDATE_TITLE: {
        de_DE: "Menge aktualisiert",
        en_GB: "Quantity Updated",
        es_ES: "Cantidad actualizada",
        fr_BE: "Quantité mise à jour",
        nl_BE: "Hoeveelheid bijgewerkt",
      },
      UPDATE_DESC: {
        de_DE: "Menge erfolgreich aktualisiert.",
        en_GB: "Quantity updated successfully.",
        es_ES: "Cantidad actualizada correctamente.",
        fr_BE: "Quantité mise à jour avec succès.",
        nl_BE: "Hoeveelheid succesvol bijgewerkt.",
      },
      REMOVE_TITLE: {
        de_DE: "Produkt entfernt",
        en_GB: "Product Removed",
        es_ES: "Producto eliminado",
        fr_BE: "Produit retiré",
        nl_BE: "Product verwijderd",
      },
      REMOVE_DESC: {
        de_DE: "Produkt wurde aus dem Warenkorb entfernt.",
        en_GB: "Product removed from the cart.",
        es_ES: "Producto eliminado del carrito.",
        fr_BE: "Produit retiré du panier.",
        nl_BE: "Product verwijderd uit de winkelwagen.",
      },
      ORDER_PLACE_ERROR_TITLE: {
        de_DE: "Bestellung fehlgeschlagen",
        en_GB: "Order Placement Error",
        es_ES: "Error al realizar el pedido",
        fr_BE: "Erreur de validation de commande",
        nl_BE: "Fout bij het plaatsen van de bestelling",
      },
      ORDER_PLACE_ERROR_DESC: {
        de_DE: "Fehler beim Aufgeben der Bestellung.",
        en_GB: "Error placing the order.",
        es_ES: "Error al realizar el pedido.",
        fr_BE: "Erreur lors de la validation de la commande.",
        nl_BE: "Fout bij het plaatsen van de bestelling.",
      },
      ORDER_PLACE_SUCCESS_DESC: {
        de_DE: "Bestellung erfolgreich aufgegeben.",
        en_GB: "Order placed successfully.",
        es_ES: "Pedido realizado correctamente.",
        fr_BE: "Commande validée avec succès.",
        nl_BE: "Bestelling succesvol geplaatst.",
      },
      ORDER_PLACE_SUCCESS_TITLE: {
        de_DE: "Bestellung erfolgreich",
        en_GB: "Order Placed Successfully",
        es_ES: "Pedido realizado correctamente",
        fr_BE: "Commande validée avec succès",
        nl_BE: "Bestelling succesvol geplaatst",
      },
    },
    NAME: {
      de_DE: "Name",
      en_GB: "Name",
      es_ES: "Nombre",
      fr_BE: "Nom",
      nl_BE: "Naam",
    },
    LANGUAGE: {
      de_DE: "Sprache",
      en_GB: "Language",
      es_ES: "Idioma",
      fr_BE: "Langue",
      nl_BE: "Taal",
    },
    QTY: {
      de_DE: "Menge",
      en_GB: "Quantity",
      es_ES: "Cantidad",
      fr_BE: "Quantité",
      nl_BE: "Hoeveelheid",
    },
    AVAILABLE: {
      de_DE: "Verfügbar",
      en_GB: "Available",
      es_ES: "Disponible",
      fr_BE: "Disponible",
      nl_BE: "Beschikbaar",
    },
    PRICE: {
      de_DE: "Preis",
      en_GB: "Price",
      es_ES: "Precio",
      fr_BE: "Prix",
      nl_BE: "Prijs",
    },
    TOTAL: {
      de_DE: "Insgesamt",
      en_GB: "Total",
      es_ES: "Total",
      fr_BE: "Total",
      nl_BE: "Totaal",
    },
    Total_W_O_VAT: {
      de_DE: "Gesamt ohne MwSt.",
      en_GB: "Total w/o VAT",
      es_ES: "Total sin IVA",
      fr_BE: "Total HTVA",
      nl_BE: "Totaal zonder BTW",
    },
    VAT: {
      de_DE: "MEHRWERTSTEUER",
      en_GB: "VAT",
      es_ES: "IVA",
      fr_BE: "TVA",
      nl_BE: "Totaal BTW",
    },
    TOTAL_WITH_VAT: {
      de_DE: "Gesamtbetrag mit MwSt.",
      en_GB: "Total with VAT",
      es_ES: "Total con IVA",
      fr_BE: "Total TVA Incl.",
      nl_BE: "Totaal incl. BTW",
    },
    PLACE_ORDER_BTN: {
      de_DE: "Bestellung aufgeben",
      en_GB: "Place order",
      es_ES: "Realizar pedido",
      fr_BE: "Passer commande",
      nl_BE: "Plaats een bestelling",
    },
    RESET_BTN: {
      de_DE: "Warenkorb zurücksetzen",
      en_GB: "Reset Cart",
      es_ES: "Restablecer cesta",
      fr_BE: "Réinitialiser le panier",
      nl_BE: "Maak winkelmandje leeg",
    },
    Title: {
      de_DE: "Inhalt des Warenkorbs",
      en_GB: "Shopping cart content",
      es_ES: "Contenido de la cesta de la compra",
      fr_BE: "Contenu du panier",
      nl_BE: "Winkelwagen inhoud",
    },
  },
  ALERT: {
    INVALID_LOGIN: {
      TITLE: {
        de_DE: "Anmeldung erforderlich",
        en_GB: "Login Required",
        es_ES: "Acceso restringido",
        fr_BE: "Connexion requise",
        nl_BE: "Inloggen vereist",
      },
      DESC: {
        de_DE: "Bitte melden Sie sich an, um auf die Website zuzugreifen.",
        en_GB: "Please log in to access the website.",
        es_ES: "Por favor, inicia sesión para acceder al sitio web.",
        fr_BE: "Veuillez vous connecter pour accéder au site web.",
        nl_BE: "Log in om toegang te krijgen tot de website.",
      },
      BODY: {
        de_DE: "Hier klicken, um sich anzumelden",
        en_GB: "Click here to Login",
        es_ES: "Haz clic aquí para iniciar sesión",
        fr_BE: "Cliquez ici pour vous connecter",
        nl_BE: "Klik hier om in te loggen",
      },
    },
    ADDED_TO_CART_TITLE: {
      de_DE: "Zum Warenkorb hinzugefügt",
      en_GB: "Added to cart",
      es_ES: "Añadido al carrito",
      fr_BE: "Ajouté au panier",
      nl_BE: "Toegevoegd aan winkelwagen",
    },
    ADDED_TO_CART_DESC: {
      de_DE: "Produkt erfolgreich zum Warenkorb hinzugefügt",
      en_GB: "Product added to cart successfully",
      es_ES: "Producto añadido al carrito correctamente",
      fr_BE: "Produit ajouté au panier avec succès",
      nl_BE: "Product succesvol aan winkelwagen toegevoegd",
    },
    UPDATED_TO_CART_TITLE: {
      de_DE: "Warenkorb aktualisieren",
      en_GB: "Update cart",
      es_ES: "Actualizar carrito",
      fr_BE: "Mettre à jour le panier",
      nl_BE: "Winkelwagen bijwerken",
    },
    UPDATED_TO_CART_DESC: {
      de_DE: "Produkt im Warenkorb erfolgreich aktualisiert",
      en_GB: "Product in cart updated successfully",
      es_ES: "Producto en el carrito actualizado correctamente",
      fr_BE: "Produit dans le panier mis à jour avec succès",
      nl_BE: "Product in winkelwagen succesvol bijgewerkt",
    },
    FAILED_ADD_CART_TITLE: {
      de_DE: "Fehler beim Hinzufügen zum Warenkorb!",
      en_GB: "Failed to add to cart!",
      es_ES: "¡Error al añadir al carrito!",
      fr_BE: "Échec de l'ajout au panier !",
      nl_BE: "Toevoegen aan winkelwagen mislukt!",
    },
    FAILED_ADD_TO_CART_DESC: {
      de_DE: "Produkt konnte nicht zum Warenkorb hinzugefügt werden!",
      en_GB: "Product failed to add to cart!",
      es_ES: "¡No se pudo añadir el producto al carrito!",
      fr_BE: "Échec de l'ajout du produit au panier !",
      nl_BE: "Product kon niet aan winkelwagen worden toegevoegd!",
    },
  },
  BEST_PRODUCTS: {
    de_DE: "Unsere besten Produkte!",
    en_GB: "Our best products!",
    es_ES: "¡Nuestros mejores productos!",
    fr_BE: "Nos meilleurs produits !",
    nl_BE: "Onze beste producten!",
  },
  ALL_PROMO: {
    de_DE: "Alle unsere Aktionen!",
    en_GB: "All our promotions!",
    es_ES: "¡Todas nuestras promociones!",
    fr_BE: "Toutes nos promotions !",
    nl_BE: "Al onze promoties!",
  },
  HOT_DEALS: {
    de_DE: "Mehr heiße Angebote anzeigen",
    en_GB: "See more hot deals",
    es_ES: "Ver más ofertas destacadas",
    fr_BE: "Voir plus de bonnes affaires",
    nl_BE: "Bekijk meer hete deals",
  },
  redis_keys: {
    CATEGORY_COMPUTED_LABELS: "CATEGORY_COMPUTED_LABELS",
    PRESALE_PERCENTAGE: "PRESALE_PERCENTAGE",
  },
});
