import revive_payload_client_4sVQNw7RlN from "/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/cld-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/cld-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/cld-nuxt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/cld-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/cld-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/cld-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_6wEQMY3tee from "/var/www/cld-nuxt/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import script_YrMPz5ICtR from "/var/www/cld-nuxt/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_tbFNToZNim from "/var/www/cld-nuxt/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_PSbK9A48ZQ from "/var/www/cld-nuxt/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import i18n_yfWm7jX06p from "/var/www/cld-nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_8SbxDRbG6Y from "/var/www/cld-nuxt/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import useBootstrap_client_ouDJvcVteA from "/var/www/cld-nuxt/plugins/useBootstrap.client.ts";
import showToast_client_i3Ts44oG79 from "/var/www/cld-nuxt/plugins/showToast.client.js";
import piniaPersistedState_WGygW4gtoP from "/var/www/cld-nuxt/plugins/piniaPersistedState.js";
import error_handler_kEP5FliEXj from "/var/www/cld-nuxt/plugins/error-handler.ts";
import fontawesome_klhsrycjcK from "/var/www/cld-nuxt/plugins/fontawesome.js";
import storage_AlMyh9gfRl from "/var/www/cld-nuxt/plugins/storage.js";
import vuetify_8NhHJigKc1 from "/var/www/cld-nuxt/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_6wEQMY3tee,
  script_YrMPz5ICtR,
  plugin_tbFNToZNim,
  plugin_PSbK9A48ZQ,
  i18n_yfWm7jX06p,
  plugin_8SbxDRbG6Y,
  useBootstrap_client_ouDJvcVteA,
  showToast_client_i3Ts44oG79,
  piniaPersistedState_WGygW4gtoP,
  error_handler_kEP5FliEXj,
  fontawesome_klhsrycjcK,
  storage_AlMyh9gfRl,
  vuetify_8NhHJigKc1
]