<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
    error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
    <div class="container">
        <div class="text-center">
            <h2>{{ error?.statusCode }}</h2>
            <button @click="handleError">Sorry! App ran into error. Click to continue</button>
        </div>
    </div>
</template>
