import { useUserStore } from "~/store/UserStore";
import nuxtStorage from "nuxt-storage";
// For using in pini stores and http call funcs
export const useUnAutorize = () => {

  const userStore = useUserStore();
  const route = useRoute();
  const router = useRouter();
  const localePath = useLocalePath();
  const { t } = useI18n();

  useNuxtApp().$showToast({ msg: t("session_expired"), type: "error" });
  // Logout http h3
  userStore.logout().then();
  userStore.setIsAuthenticated();
  router.push(
    localePath(`/account/login?redirectTo=${route.fullPath}`)
  );
};
