import { defineStore } from "pinia";
import { ref } from "vue";
import { CLD_ENV, NODE_ENV } from "~/constants/env";
import nuxtStorage from "nuxt-storage";
import { useURLs } from "~/constants/api.urls";
import { useI18n } from "vue-i18n";
import { useUnAutorize } from "~/composables/unAuthHandler";
import axios from "axios";
import { useCldURLs } from "~/constants/apiv2.urls";
import { getRedis } from "~/api/redis";

export const useUtilityStore = defineStore("utility", () => {
  const selectedLocale = ref("en");
  const newsLetter = ref();
  const { locale, setLocale } = useI18n();
  const countryList = ref([]);
  const countryListDropDown = ref([]);
  const languageMap = ref({
    fr: "fr_BE",
    en: "en_GB",
    de: "de_DE",
    nl: "nl_BE",
    es: "es_ES",
    sv: "sv_SE",
  });
  const selectedLanguage = ref(languageMap.value["en"]); //ref("en");


  // products list css state
  const gridListType = ref('large')
  const SetGridType = (value) => {
    gridListType.value = value
  }

  const setLanguage = (language) => {
    selectedLanguage.value = languageMap.value[language];
    selectedLocale.value = language;
    localStorage?.setItem("selectedLanguage", language);
    nuxtStorage.localStorage.setData("selectedLanguage", language, 100, "y");
    nuxtStorage.localStorage.setData(
      "selectedLocale",
      selectedLocale.value,
      100,
      "y"
    );

    setLocale(language);
    // locale.value = selectedLanguage.value.split("_")[0];
  };
  const downloadPdfDoc = async ({ id, journal, piece }) => {
   
    const url = `${useCldURLs[CLD_ENV].utility.download_pdf}?id=${id}&journal=${journal}&piece=${piece}`;
    // const token = useCookie('authToken').value;
    // if (!token) {
    //   return;
    // }
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        Accept: "application/pdf",
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    },true);
    const downloadUrl = window.URL.createObjectURL(data.value);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `${journal}_${piece}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  // news letter registration
  const createContactCrm = async ({ firstName, lastName, email }) => {
    const url = useURLs[NODE_ENV].CRM_CREATE_CONTACT;
    const payload = JSON.stringify({ firstName, lastName, email });
    const { data } = await useFetch(url, {
      key: `${payload}`,
      method: "POST",
      body: payload,
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      retry: 2,
    });

    if (data.value && data.value.contact) {
      newsLetter.value = data.value.contact;
    } else {
      newsLetter.value = false;
    }
  };
  const downloadImages = async (images, id) => {
    try {
      const imagesPath = images.map(
        (image) => `https://assets.cld.be/cdn-cgi/image/quality=100/${image}`
      );
      const url = useURLs[NODE_ENV].IMAGE_FILE;
      const response = await axios.post(
        url,
        {
          images: imagesPath,
        },
        {
          responseType: "blob",
        }
      );

      // const { data } = await useFetch(url, {
      //   key: id,
      //   method: "POST",
      //   body: {
      //     images: imagesPath,
      //   },
      //   headers: {
      //     client_id: useURLs[NODE_ENV].CLIENT_ID,
      //     client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      //     "Content-Type": "application/json",
      //   },
      //   retry: 2,
      // });

      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = objectUrl;
      link.setAttribute("download", `${id}_images.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return;
    }
  };

  const getCountries = async () => {
    getRedis("COUNTRIES").then((data) => {
      try {
        const _countrylist = JSON.parse(data)
        if (_countrylist && _countrylist?.length) {
          countryListDropDown.value = _countrylist;
          return;
        }
      } catch (error) {
        console.error("error getting countries from cache", error);
      }
    });
    const url = useCldURLs[CLD_ENV].utility.countries;
    const { data } = await useFetch(url, {
      method: "GET",
    });
    
    if (data.value?.length) {
      countryListDropDown.value = data.value.map((country) => ({
        Iso: country.Iso,
        Name: country.Name,
        isEU: country.Eucountry
      }));
      setRedis({ key: "COUNTRIES", value: JSON.stringify(countryListDropDown.value) });
    }
  }
  return {
    selectedLanguage,
    selectedLocale,
    languageMap,
    setLanguage,
    downloadPdfDoc,
    createContactCrm,
    newsLetter,
    downloadImages,
    gridListType,
    SetGridType,
    getCountries,
    countryList,
    countryListDropDown,
  };
});
