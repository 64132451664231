export const validateNSendContact = async ({ token }) => {
  const data = await useFetch("/api/validateTurnstileNSendContact", {
    key: token.substring(0, 10),
    method: "POST",
    body: JSON.stringify({ token }),
    headers: {
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  return data;
};
