const NODEJS_PATH_LOCAL_DEV = "http://localhost:3000/";
const NODEJS_PATH_PROD = "https://search.cld.eu/";
const NODEJS_PATH_STAGING = "https://devsearch.cld.eu/";

const CLD_PATH_LOCAL_DEV = "https://localhost:44307/";
const CLD_PATH_PROD = "https://webapi.cld.eu/";
const CLD_PATH_STAGING = "https://dev.cld.eu/";

const CLD_FE_PATH_LOCAL_DEV = "https://localhost:3001/";
const CLD_FE_PATH_PROD = "https://cld.eu/";
const CLD_FE_PATH_STAGING = "https://devweb.cld.eu/";

export const useURLs = Object.freeze({
  production: {
    BE_URL: NODEJS_PATH_PROD,
    CLD_URL: CLD_PATH_PROD,
    CLD_FE_URL: CLD_FE_PATH_PROD,
    CLIENT_ID: "cf8y07lxiaf0xa61f5brsxlunw4t31he",
    CLIENT_SECERET: "33IlwGq5Ek-DVR6mk8ukhA",
    GET_REDIS_URL: `${NODEJS_PATH_PROD}redis/get`,
    SET_REDIS_URL: `${NODEJS_PATH_PROD}redis/set`,
    PRODUCT_SEARCH_URL: `${NODEJS_PATH_PROD}products/search/text`,
    PRODUCT_RELEASE_SEARCH_URL: `${NODEJS_PATH_PROD}products/search/release`,
    PRODUCT_ALL_FUTURE_RELEASE_SEARCH_URL: `${NODEJS_PATH_PROD}products/search/all-future-release`,
    PRODUCT_FIND_URL: `${NODEJS_PATH_PROD}products/find`,
    PRODUCT_FIND_MANY_URL: `${NODEJS_PATH_PROD}products/findmany`,
    PRODUCT_STOCK_BY_IDS_URL: `${NODEJS_PATH_PROD}products/stocks`,
    ATTRIBUTE_FIND_URL: `${NODEJS_PATH_PROD}attributes`,
    SUGGESTION_URL: `${NODEJS_PATH_PROD}products/suggestion?text=`,
    CATEGORY_FILTERS_URL: `${NODEJS_PATH_PROD}products/category`,
    FILTERS_LABELS_URL: `${NODEJS_PATH_PROD}attributes/labels`,
    CATEGORY_PARENT: `${NODEJS_PATH_PROD}category/parents`,
    CATEGORY_PROMO_MENU: `${NODEJS_PATH_PROD}category/category-promo-menu`,
    USER: `${NODEJS_PATH_PROD}users`,
    CLD_ADD_TO_CART: `${CLD_PATH_PROD}Cart/AddToCart`, // done
    // CLD_RETRIEVE_SESSION: `${CLD_PATH_PROD}Cart/GetSession`,
    CLD_BACK_ORDERS: `${CLD_PATH_PROD}Cart/BackOrders`, // done
    GET_CART_ITEMS: `${CLD_PATH_PROD}cart/GetCartItems`, // done
    UPDATE_QTY_IN_CART: `${CLD_PATH_PROD}cart/UpdateQtyInCart`, // done
    RESET_CART: `${CLD_PATH_PROD}cart/ResetCart`,
    REMOVE_FROM_CART: `${CLD_PATH_PROD}cart/RemoveLineFromCart`,
    CAR_CLIENT_PLACE_ORDER: `${CLD_PATH_PROD}cart/CanPlaceOrder`,
    CLD_USER: `${CLD_PATH_PROD}User/Me`,
    IS_PO_Required: `${CLD_PATH_PROD}User/isPurchaseOrderRequired`,
    CLD_UPDATE_EMAILS: `${CLD_PATH_PROD}User/UpdateEmails`,
    CLD_SEND_CONTACT_EMAIL: `${CLD_PATH_PROD}User/SendEmail`,
    SEND_CREDENTIALS_EMAIL: `${CLD_PATH_PROD}User/sendCredentialsEmail`,
    PRODUCT_PRESALE: `${CLD_PATH_PROD}Product/GetProductPreSalePercentage`,
    BRAND_N_LICENSE_AGG: `${NODEJS_PATH_PROD}attributes/brand_n_license_agg`,
    CLD_AUTH: `${CLD_PATH_PROD}user/auth`,
    CLD_PREREGISTOR: `${CLD_PATH_PROD}user/PreSignup`,
    EMAIL_EXISTS: `${CLD_PATH_PROD}user/emailExists`,
    CHECK_VAT_EXIST_AND_ISVALID: `${CLD_PATH_PROD}user/CheckVatExistAndisValid`,
    CLD_POSTREGISTOR: `${CLD_PATH_PROD}user/PostSignup`,
    ORDER_HISTORY: `${CLD_PATH_PROD}order/history`,
    ORDER_REOPEN: `${CLD_PATH_PROD}order/reopen`,
    DOWNLOAD_ORDER_HISTORY: `${CLD_PATH_PROD}order/downloadhistory`,
    SEARCH_ORDER_HISTORY: `${CLD_PATH_PROD}order/searchhistory`,
    SEARCH_ORDER_WEBORD: `${CLD_PATH_PROD}order/searchorders`,
    SEARCH_ORDER_SUPP: `${CLD_PATH_PROD}order/SearchSupplierOrders`,
    ALL_BACK_ORDERS: `${CLD_PATH_PROD}order/GetBackOrders`,
    DELIVERABLE_ORDERS: `${CLD_PATH_PROD}order/GetDeliverableOrders`,
    UNDELIVERABLE_ORDERS: `${CLD_PATH_PROD}order/GetUnDeliverableOrders`,
    SEARCH_OPENDOCS: `${CLD_PATH_PROD}order/opendocs`,
    ACCOUNTING_SITUATION: `${CLD_PATH_PROD}order/accountingsituation`,
    SEARCH_PRICEDOWN: `${CLD_PATH_PROD}pricedown/GetPriceDown`,
    PRICEDOWN_DETAILS: `${CLD_PATH_PROD}pricedown/PriceDownDetails`,
    CHECK_PRESENCE_SHIPPING_COST: `${CLD_PATH_PROD}shipping/CheckPresenceOfShippingCost`,
    ADD_SHIPPING_COST: `${CLD_PATH_PROD}shipping/AddShippingCost`,
    INSERT_PRICEDOWN_ITEM_QUANTITY: `${CLD_PATH_PROD}pricedown/InsertPriceDownItemQuantity`,
    UPDATE_PRICEDOWN_ITEM_QUANTITY: `${CLD_PATH_PROD}pricedown/UpdatePriceDownItemQuantity`,
    DOWNLOAD_UTIL_DOC: `${CLD_PATH_PROD}utils/DownloadPdf`,
    CRM_CREATE_CONTACT: `${NODEJS_PATH_PROD}crm/contact`,
    UPDATE_USER_NEWSLETTER_STATUS: `${NODEJS_PATH_PROD}users/updateusernewsletterstatus`,
    IMAGE_FILE: `${NODEJS_PATH_PROD}file/image-proxy`,
  },
  staging: {
    BE_URL: NODEJS_PATH_STAGING,
    CLD_URL: CLD_PATH_STAGING,
    CLD_FE_URL: CLD_FE_PATH_STAGING,
    CLIENT_ID: "cf8y07lxiaf0xa61f5brsxlunw4t31he",
    CLIENT_SECERET: "33IlwGq5Ek-DVR6mk8ukhA",
    GET_REDIS_URL: `${NODEJS_PATH_STAGING}redis/get`,
    SET_REDIS_URL: `${NODEJS_PATH_STAGING}redis/set`,
    PRODUCT_SEARCH_URL: `${NODEJS_PATH_STAGING}products/search/text`,
    PRODUCT_RELEASE_SEARCH_URL: `${NODEJS_PATH_STAGING}products/search/release`,
    PRODUCT_ALL_FUTURE_RELEASE_SEARCH_URL: `${NODEJS_PATH_STAGING}products/search/all-future-release`,
    PRODUCT_FIND_URL: `${NODEJS_PATH_STAGING}products/find`,
    PRODUCT_FIND_MANY_URL: `${NODEJS_PATH_STAGING}products/findmany`,
    PRODUCT_STOCK_BY_IDS_URL: `${NODEJS_PATH_STAGING}products/stocks`,
    ATTRIBUTE_FIND_URL: `${NODEJS_PATH_STAGING}attributes`,
    SUGGESTION_URL: `${NODEJS_PATH_STAGING}products/suggestion?text=`,
    CATEGORY_FILTERS_URL: `${NODEJS_PATH_STAGING}products/category`,
    FILTERS_LABELS_URL: `${NODEJS_PATH_STAGING}attributes/labels`,
    CATEGORY_PARENT: `${NODEJS_PATH_STAGING}category/parents`,
    CATEGORY_PROMO_MENU: `${NODEJS_PATH_STAGING}category/category-promo-menu`,
    USER: `${NODEJS_PATH_STAGING}users`,
    CLD_ADD_TO_CART: `${CLD_PATH_STAGING}Cart/AddToCart`,
    // CLD_RETRIEVE_SESSION: `${CLD_PATH_STAGING}Cart/GetSession`,
    CLD_BACK_ORDERS: `${CLD_PATH_STAGING}Cart/BackOrders`,
    GET_CART_ITEMS: `${CLD_PATH_STAGING}cart/GetCartItems`,
    UPDATE_QTY_IN_CART: `${CLD_PATH_STAGING}cart/UpdateQtyInCart`,
    RESET_CART: `${CLD_PATH_STAGING}cart/ResetCart`,
    REMOVE_FROM_CART: `${CLD_PATH_STAGING}cart/RemoveLineFromCart`,
    CAR_CLIENT_PLACE_ORDER: `${CLD_PATH_STAGING}cart/CanPlaceOrder`,
    CLD_USER: `${CLD_PATH_STAGING}User/Me`,
    IS_PO_Required: `${CLD_PATH_STAGING}User/isPurchaseOrderRequired`,
    CLD_UPDATE_EMAILS: `${CLD_PATH_STAGING}User/UpdateEmails`,
    CLD_SEND_CONTACT_EMAIL: `${CLD_PATH_STAGING}User/SendEmail`,
    SEND_CREDENTIALS_EMAIL: `${CLD_PATH_STAGING}User/sendCredentialsEmail`,
    PRODUCT_PRESALE: `${CLD_PATH_STAGING}Product/GetProductPreSalePercentage`,
    BRAND_N_LICENSE_AGG: `${NODEJS_PATH_STAGING}attributes/brand_n_license_agg`,
    CLD_AUTH: `${CLD_PATH_STAGING}user/auth`,
    CLD_PREREGISTOR: `${CLD_PATH_STAGING}user/PreSignup`,
    EMAIL_EXISTS: `${CLD_PATH_STAGING}user/emailExists`,
    CHECK_VAT_EXIST_AND_ISVALID: `${CLD_PATH_STAGING}user/CheckVatExistAndisValid`,
    CLD_POSTREGISTOR: `${CLD_PATH_STAGING}user/PostSignup`,
    ORDER_HISTORY: `${CLD_PATH_STAGING}order/history`,
    ORDER_REOPEN: `${CLD_PATH_STAGING}order/reopen`,
    DOWNLOAD_ORDER_HISTORY: `${CLD_PATH_STAGING}order/downloadhistory`,
    SEARCH_ORDER_HISTORY: `${CLD_PATH_STAGING}order/searchhistory`,
    SEARCH_ORDER_WEBORD: `${CLD_PATH_STAGING}order/searchorders`,
    SEARCH_ORDER_SUPP: `${CLD_PATH_STAGING}order/SearchSupplierOrders`,
    ALL_BACK_ORDERS: `${CLD_PATH_STAGING}order/GetBackOrders`,
    DELIVERABLE_ORDERS: `${CLD_PATH_STAGING}order/GetDeliverableOrders`,
    UNDELIVERABLE_ORDERS: `${CLD_PATH_STAGING}order/GetUnDeliverableOrders`,
    SEARCH_OPENDOCS: `${CLD_PATH_STAGING}order/opendocs`,
    ACCOUNTING_SITUATION: `${CLD_PATH_STAGING}order/accountingsituation`,
    SEARCH_PRICEDOWN: `${CLD_PATH_STAGING}pricedown/GetPriceDown`,
    PRICEDOWN_DETAILS: `${CLD_PATH_STAGING}pricedown/PriceDownDetails`,
    CHECK_PRESENCE_SHIPPING_COST: `${CLD_PATH_STAGING}shipping/CheckPresenceOfShippingCost`,
    ADD_SHIPPING_COST: `${CLD_PATH_STAGING}shipping/AddShippingCost`,
    INSERT_PRICEDOWN_ITEM_QUANTITY: `${CLD_PATH_STAGING}pricedown/InsertPriceDownItemQuantity`,
    UPDATE_PRICEDOWN_ITEM_QUANTITY: `${CLD_PATH_STAGING}pricedown/UpdatePriceDownItemQuantity`,
    DOWNLOAD_UTIL_DOC: `${CLD_PATH_STAGING}utils/DownloadPdf`,
    CRM_CREATE_CONTACT: `${NODEJS_PATH_STAGING}crm/contact`,
    UPDATE_USER_NEWSLETTER_STATUS: `${NODEJS_PATH_STAGING}users/updateusernewsletterstatus`,
    IMAGE_FILE: `${NODEJS_PATH_STAGING}file/image-proxy`,
  },
  development: {
    BE_URL: NODEJS_PATH_LOCAL_DEV,
    CLD_URL: CLD_PATH_LOCAL_DEV,
    CLD_FE_URL: CLD_PATH_LOCAL_DEV,
    CLD_FE_PATH_LOCAL_DEV: CLD_FE_PATH_LOCAL_DEV,
    CLIENT_ID: "cf8y07lxiaf0xa61f5brsxlunw4t31he",
    CLIENT_SECERET: "33IlwGq5Ek-DVR6mk8ukhA",
    GET_REDIS_URL: `${NODEJS_PATH_LOCAL_DEV}redis/get`,
    SET_REDIS_URL: `${NODEJS_PATH_LOCAL_DEV}redis/set`,
    PRODUCT_SEARCH_URL: `${NODEJS_PATH_LOCAL_DEV}products/search/text`,
    PRODUCT_RELEASE_SEARCH_URL: `${NODEJS_PATH_LOCAL_DEV}products/search/release`,
    PRODUCT_ALL_FUTURE_RELEASE_SEARCH_URL: `${NODEJS_PATH_LOCAL_DEV}products/search/all-future-release`,
    PRODUCT_FIND_URL: `${NODEJS_PATH_LOCAL_DEV}products/find`,
    PRODUCT_FIND_MANY_URL: `${NODEJS_PATH_LOCAL_DEV}products/findmany`,
    PRODUCT_STOCK_BY_IDS_URL: `${NODEJS_PATH_LOCAL_DEV}products/stocks`,
    ATTRIBUTE_FIND_URL: `${NODEJS_PATH_LOCAL_DEV}attributes`,
    SUGGESTION_URL: `${NODEJS_PATH_LOCAL_DEV}products/suggestion?text=`,
    CATEGORY_FILTERS_URL: `${NODEJS_PATH_LOCAL_DEV}products/category`,
    FILTERS_LABELS_URL: `${NODEJS_PATH_LOCAL_DEV}attributes/labels`,
    CATEGORY_PARENT: `${NODEJS_PATH_LOCAL_DEV}category/parents`,
    CATEGORY_PROMO_MENU: `${NODEJS_PATH_LOCAL_DEV}category/category-promo-menu`,
    USER: `${NODEJS_PATH_LOCAL_DEV}users`,
    CLD_ADD_TO_CART: `${CLD_PATH_LOCAL_DEV}Cart/AddToCart`,
    // CLD_RETRIEVE_SESSION: `${CLD_PATH_LOCAL_DEV}Cart/GetSession`,
    CLD_BACK_ORDERS: `${CLD_PATH_LOCAL_DEV}Cart/BackOrders`,
    GET_CART_ITEMS: `${CLD_PATH_LOCAL_DEV}cart/GetCartItems`,
    UPDATE_QTY_IN_CART: `${CLD_PATH_LOCAL_DEV}cart/UpdateQtyInCart`,
    RESET_CART: `${CLD_PATH_LOCAL_DEV}cart/ResetCart`,
    REMOVE_FROM_CART: `${CLD_PATH_LOCAL_DEV}cart/RemoveLineFromCart`,
    CAR_CLIENT_PLACE_ORDER: `${CLD_PATH_LOCAL_DEV}cart/CanPlaceOrder`,
    CLD_USER: `${CLD_PATH_LOCAL_DEV}User/Me`,
    IS_PO_Required: `${CLD_PATH_LOCAL_DEV}User/isPurchaseOrderRequired`,
    CLD_UPDATE_EMAILS: `${CLD_PATH_LOCAL_DEV}User/UpdateEmails`,
    CLD_SEND_CONTACT_EMAIL: `${CLD_PATH_LOCAL_DEV}User/SendEmail`,
    SEND_CREDENTIALS_EMAIL: `${CLD_PATH_LOCAL_DEV}User/sendCredentialsEmail`,
    PRODUCT_PRESALE: `${CLD_PATH_LOCAL_DEV}Product/GetProductPreSalePercentage`,
    BRAND_N_LICENSE_AGG: `${NODEJS_PATH_LOCAL_DEV}attributes/brand_n_license_agg`,
    CLD_AUTH: `${CLD_PATH_LOCAL_DEV}user/auth`,
    CLD_PREREGISTOR: `${CLD_PATH_LOCAL_DEV}user/PreSignup`,
    EMAIL_EXISTS: `${CLD_PATH_LOCAL_DEV}user/emailExists`,
    CHECK_VAT_EXIST_AND_ISVALID: `${CLD_PATH_LOCAL_DEV}user/CheckVatExistAndisValid`,
    CLD_POSTREGISTOR: `${CLD_PATH_LOCAL_DEV}user/PostSignup`,
    ORDER_HISTORY: `${CLD_PATH_LOCAL_DEV}order/history`,
    ORDER_REOPEN: `${CLD_PATH_LOCAL_DEV}order/reopen`,
    DOWNLOAD_ORDER_HISTORY: `${CLD_PATH_LOCAL_DEV}order/downloadhistory`,
    SEARCH_ORDER_HISTORY: `${CLD_PATH_LOCAL_DEV}order/searchhistory`,
    SEARCH_ORDER_WEBORD: `${CLD_PATH_LOCAL_DEV}order/searchorders`,
    SEARCH_ORDER_SUPP: `${CLD_PATH_LOCAL_DEV}order/SearchSupplierOrders`,
    ALL_BACK_ORDERS: `${CLD_PATH_LOCAL_DEV}order/GetBackOrders`,
    DELIVERABLE_ORDERS: `${CLD_PATH_LOCAL_DEV}order/GetDeliverableOrders`,
    UNDELIVERABLE_ORDERS: `${CLD_PATH_LOCAL_DEV}order/GetUnDeliverableOrders`,
    SEARCH_OPENDOCS: `${CLD_PATH_LOCAL_DEV}order/opendocs`,
    ACCOUNTING_SITUATION: `${CLD_PATH_LOCAL_DEV}order/accountingsituation`,
    SEARCH_PRICEDOWN: `${CLD_PATH_LOCAL_DEV}pricedown/GetPriceDown`,
    PRICEDOWN_DETAILS: `${CLD_PATH_LOCAL_DEV}pricedown/PriceDownDetails`,
    CHECK_PRESENCE_SHIPPING_COST: `${CLD_PATH_LOCAL_DEV}shipping/CheckPresenceOfShippingCost`,
    ADD_SHIPPING_COST: `${CLD_PATH_LOCAL_DEV}shipping/AddShippingCost`,
    INSERT_PRICEDOWN_ITEM_QUANTITY: `${CLD_PATH_LOCAL_DEV}pricedown/InsertPriceDownItemQuantity`,
    UPDATE_PRICEDOWN_ITEM_QUANTITY: `${CLD_PATH_LOCAL_DEV}pricedown/UpdatePriceDownItemQuantity`,
    DOWNLOAD_UTIL_DOC: `${CLD_PATH_LOCAL_DEV}utils/DownloadPdf`,
    CRM_CREATE_CONTACT: `${NODEJS_PATH_LOCAL_DEV}crm/contact`,
    UPDATE_USER_NEWSLETTER_STATUS: `${NODEJS_PATH_LOCAL_DEV}users/updateusernewsletterstatus`,
    IMAGE_FILE: `${NODEJS_PATH_LOCAL_DEV}file/image-proxy`,
  },
});
