import type { UseFetchOptions } from "#app";

export const useFetchApi = (url: string, rootOptions?: UseFetchOptions<object>, isAuthRequire: boolean = true) => {
    return useFetch(url, {
        ...rootOptions,
        async onRequest({ request, options }) {
            let authToken = ''
            if (useNuxtApp().ssrContext) {
                authToken = useCookie<string>('authToken', {}).value
                // console.log("SSR context detected================= add auth token to headers", authToken);
            }
            else {
                await $fetch('/api/healthcheck', {
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    onResponse(context) {
                        // console.log("Healthcheck response received");
                        // console.log("headers", context.response.headers);
                        authToken = context.response.headers.get('X-Auth-Token') || '';
                        // console.log("authToken", authToken);
                    },
                });
            }
            if (authToken) {
                // Ensure headers is an instance of Headers
                options.headers = {

                    ...rootOptions?.headers, // Spread existing headers, if any
                    ...options.headers, // Spread existing headers, if any
                    // @ts-ignore
                    "Authorization": `Bearer  ${authToken}`, // Set the new header
                };
                // options.headers.append("Authorization", `Bearer  ${authToken}`);
            }
            else if (isAuthRequire) {
                // console.info(`Request url${url} cancelled because authentication is required.`);
                throw new Error(`Request url${url} cancelled because authentication is required.`);
            }
        },
        // async onRequestError({ request, options, error }) {
        //     console.log("[fetch request error]");
        // },
        // async onResponse({ request, response, options }) {
        //     console.log("[fetch response]");
        // },
        // async onResponseError({ request, response, options }) {
        //     console.log("[fetch response error]");
        // },
    });
};
